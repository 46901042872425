// question_form_controller.js
import { Controller } from "stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.formTarget.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        this.formTarget.dataset.answered = true;
        const event = new Event('answerEvent');
        document.dispatchEvent(event);
      } else {
        this.formTarget.reset()
      }
    });
  }

  answer( event ) {
    navigator.submitForm(this.formTarget)
  }
}

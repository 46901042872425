import { Controller } from "stimulus";
import { Tab } from "bootstrap";

export default class extends Controller {
  static targets = [
    "questionForm",
    "submit",
    "nextButton",
    "section",
    "comments",
    "fillableComments",
  ];
  static values = { totalQuestions: Number, sectionIndex: Number };

  connect() {
    document.addEventListener('answerEvent', this.answerQuestion.bind(this));
    this.countAnsweredQuestions();
    this.loadSection();
    this.enableNextButton();
  }

  commentsChanged() {
    this.commentsTarget.value = this.fillableCommentsTarget.value;
  }

  enableSubmitButton() {
    this.submitTarget.disabled = false;
  }

  countAnsweredQuestions() {
    let answeredQuestionsCount = 0;

    this.questionFormTargets.forEach((element, index) => {
      answeredQuestionsCount += element.dataset.answered == "true" ? 1 : 0;
    });

    return answeredQuestionsCount;
  }

  answerQuestion(event) {
    this.enableNextButton();
  }

  enableNextButton() {
    let currentSection = this.sectionTargets[this.sectionIndexValue];
    let sectionQuestionsCount = parseInt(currentSection.dataset.questionsCount);
    let sectionAnswredQuestionsCount = currentSection.querySelectorAll(
        "[data-answered='true']"
    ).length;

    if (sectionAnswredQuestionsCount == sectionQuestionsCount) {
      this.nextButtonTargets.forEach((element, index) => {
        element.classList.remove("disabled");
      });
    }
  }

  loadSection() {
    this.sectionIndexValue = parseInt(window.location.hash.split("#")[1]) || 0;
    let sections = [...document.getElementsByClassName("nav-link")];

    let section = sections[this.sectionIndexValue];
    let tab = new Tab(section);

    tab.show();
  }

  nextSection(event) {
    this.sectionIndexValue++;
    let _this = this;

    if (this.sectionIndexValue >= this.sectionTargets.length - 1) {
      this.nextButtonTargets.forEach((element, index) => {
        element.classList.add("d-none");
        _this.enableSubmitButton();
      });
    } else {
      window.location = `#${this.sectionIndexValue}`;
      window.scrollTo({ top: 0, behavior: "smooth" });

      this.nextButtonTargets.forEach((element, index) => {
        element.classList.add("disabled");
      });
      _this.enableNextButton();
    }
  }

  leavingPage(event) {
    return "THIS WON'T DISPLAY";
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["percentage", "totalProgress"]
  static values = {
     sectionNames: Array
   }

  connect() {
    document.addEventListener('answerEvent', this.calculateProgress.bind(this));
    this.calculateProgress();
  }

  calculateProgress() {
    let totalAnswered = 0;
    let totalQuestions = 0;

    this.sectionNamesValue.forEach(section => {
      const sectionAnswers = document.getElementsByClassName(section);
      totalQuestions += sectionAnswers.length;

      const answeredQuestions = Array.from(sectionAnswers).filter(e => e.dataset['answered'] === 'true');

      totalAnswered += answeredQuestions.length;
    });

    const totalProgress = totalQuestions > 0 ? Math.round((100 * totalAnswered) / totalQuestions) : 0;

    this.totalProgressTarget.textContent = totalProgress + "%";
  }
}
